/*------------------------------------------------------------------------------
  Header
-------------------------------------------------------------------------------*/
@import '../../variables.less';

.xvia-header-area {
  box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.10);
}

.xvia-header-spacing, .xvia-header-topbar {
  padding: 10px 285px;
}

.xvia-header-spacing {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
}

.xvia-header-topbar {
  background-color: #093089;
  display: flex;
  flex-direction: row;

  a {
    outline: none;
    text-decoration: none;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
}


.xvia-header-info {
  display: inline-block;
  font-size: 14px;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  span {
    font-weight: 700;
    color: #fff;
  }

  a {
    color: #fff;
  }

  &__container {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}

.xvia-header-quick-links {
  text-align: right;

  a {
    display: inline-block;
    margin-left: 15px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
  }
}

.xvia-header-elements {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.xvia-header-btn {
  button {
    display: block;
    color: #fff;
    background: #093089;
    padding: 0 38px;
    height: 60px;
    line-height: 60px;
    border-radius: 15px;
    font-weight: 700;
    outline: none;
    text-decoration: none;
    transition: all 0.3s linear 0s;
    border: none;
    cursor: pointer;
  }

  &__inactive {
    button {
      color: #000;
      background: #f8f8f8;
    }
  }

  &__loading {
    button {
      opacity: .2;
    }
  }
}

.xvia-header-user-actions {
  display: flex;
  align-items: center;
}

.xvia-header-user-notifications {
  position: relative;
  width: 52px;
  margin-right: 0.8em;

  &__icon {
    width: 32px;
    margin-right: 8px;
  }

  &__unread {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 6.5px;
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0px;
    top: 0px;
    background: #0048B6;
    border-radius: 64px;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.14px;
  }
}

.xvia-header-topbar__mobile {
  display: none;
}

.xvia-header-topbar__desktop {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-links, &-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    a {
      color: white;
      text-transform: uppercase;
    }

    svg {
      color: white;
      font-size: 18px;
    }
  }
}

.xvia-header-logo {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    max-width: 200px;
  }
}

/* Small */
@media (max-width: 767px) {
  .xvia-header-topbar__desktop {
    display: none;
  }

  .xvia-header-topbar__drawer-mobile {
    flex: 1;
    background-color: @primary-light;

    padding: 16px;

    &__close {
      display: flex;
      justify-content: flex-end;
      
      svg {
        color: rgba(255, 255, 255, 0.689);
        font-size: 18px;
      }
    }

    &__links {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;

      svg {
        color: white;
        font-size: 18px;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      flex: 1;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;

      li a {
        color: white;
        text-transform: uppercase;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .xvia-header-topbar__mobile {    
    display: flex;
    flex-direction: row;

    & .container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;

      gap: 16px;
    }

    svg {
      color: white;
      font-size: 20px;
    }
  }

  .xvia-header-topbar, .xvia-header-spacing {
      padding: 10px 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .xvia-header-topbar, .xvia-header-spacing  {
      padding: 10px 15px;
  }
}

@media (min-width: 992px) and (max-width: 1501px) {
  .xvia-header-topbar, .xvia-header-spacing  {
      padding: 10px 80px;
  }
}

/* Large */
@media (min-width: 1501px) and (max-width: 1800px) {
  .xvia-header-topbar, .xvia-header-spacing  {
      padding: 10px 100px;
  }
}

