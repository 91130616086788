/*------------------------------------------------------------------------------
  Header
-------------------------------------------------------------------------------*/

.xvia-content-area {
  padding: 16px 285px;
  flex: 1;
  background-color: #f8f8f8;
}

.xvia-login-required-area {
  background: url("https://cdn-icons-png.flaticon.com/512/64/64797.png") no-repeat top right;
  background-size: 120px;
}

.xvia-content-frame {
  flex: 1;
  border: none;
  width: 100%;
  height: 100%;
}

/* Small */
@media (max-width: 767px) {
  .xvia-content-area {
    padding: 10px 15px;
  }

  .xvia-login-required-area {
    background: none;
  }
}


@media (min-width: 768px) and (max-width: 991px) {
  .xvia-content-area {
      padding: 10px 15px;
  }
}

@media (min-width: 992px) and (max-width: 1501px) {
  .xvia-content-area  {
      padding: 10px 80px;
  }
}

/* Large */
@media (min-width: 1501px) and (max-width: 1800px) {
  .xvia-content-area  {
    padding: 10px 100px;
  }
}


