.notification-row {
  cursor: pointer;

  &__read {
    background: rgba(43, 93, 198, 0.06);
  }

  &__content {
    color: #6d6e71;
    padding: 26px 0;

    &-title {
      margin-bottom: 0px;
    }

    &-description {
      color: #777;
      margin-bottom: 0px;
    }

    &-btn {
      svg {
        justify-content: flex-end;
        margin-right: 2rem;
      }
    }
  }
}

.notification-empty {
  text-align: center;
  margin-bottom: 0;
}

.notification-title {
  margin-bottom: 0;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 133.4%;
}

.notification-date {
  color: rgba(0, 0, 0, 0.6);
}

.notification-description {
  color: rgba(0, 0, 0, 0.87);
}

.notification-icon {
  font-size: 26px;
  margin: 0 1.5rem;
}

.modal-notification {
  animation-duration: 0s !important;

  .ant-modal-header {
    border-bottom: none;
  }
}